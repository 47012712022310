<template>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="
          d-flex
          align-items-center
          justify-content-center justify-content-sm-start
        "
      >
        <span class="text-muted"
          >{{ $t("Showing") }} {{ dataMeta.from }} {{ $t("To") }}
          {{ dataMeta.to }} {{ $t("Of") }} {{ dataMeta.of }}
          {{ $t("Entries") }}</span
        >
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="
          d-flex
          align-items-center
          justify-content-center justify-content-sm-end
        "
      >
        <b-pagination
          v-model="currentPage.page"
          :total-rows="totalUsers"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="pageChanged"
        >
          <template #prev-text>
            <i class="fas fa-arrow-left" size="10"></i>
          </template>
          <template #next-text>
            <i class="fas fa-arrow-right" size="10"></i>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BPagination } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BPagination,
  },
  props: {
    dataMeta: {
      type: Object,
      default: () => ({
        from: 0,
        to: 0,
        of: 0,
      }),
    },
    totalUsers: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    pageChanged(page) {
      this.$emit("page-changed", page);
    },
  },
};
</script>

<style>
</style>