import { userAxios } from '../../index'

const allergyList = async (perPage) => {
    try {
        return await userAxios.get(`allergies/all/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const allergyCreate = async (data) => {
    try {
        return await userAxios.post('allergies', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const allergyUpdate = async (id, data) => {
    try {
        return await userAxios.put(`allergies/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const allergyDelete = async (id) => {
    try {
        return await userAxios.delete(`allergies/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const allergyPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`allergies/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const allergyFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`allergies/search/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const allergyFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`allergies/search/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    allergyList,
    allergyCreate,
    allergyUpdate,
    allergyDelete,
    allergyPagination,
    allergyFilter,
    allergyFilterPagination
}