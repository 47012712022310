<template>
  <b-card-header class="p-1">
    <b-card-title class="font-medium-2 text-center">
      <i :class="iconTable" size="18"></i>
      <span class="align-middle ml-50"> {{ $t(titleTable) }}</span>
    </b-card-title>
  </b-card-header>
</template>

<script>
import { BCardHeader, BCardTitle } from "bootstrap-vue";
export default {
  components: {
    BCardHeader,
    BCardTitle,
  },
  props: {
    titleTable: {
      type: String,
      default: "",
    },
    iconTable: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.card .card-header {
  display: block;
}
</style>
