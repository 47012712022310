<template>
  <!-- component loading   -->
  <div><span class="mr-1">{{ $t('Loading') }}...</span><b-spinner small/></div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
export default {
  components: {
    BSpinner,
  },
};
</script>

<style>
</style>