<template>
  <b-button @click="goBack" variant="warning" class="btn-icon btn-circle">
     <i class="fas fa-undo icon-nm" size="18"></i>
  </b-button>
</template>

<script>
import { BButton } from "bootstrap-vue";
export default {
  components: {
    BButton,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>